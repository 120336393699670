import React from 'react';

import {Box, Typography} from '@mui/material';
import {useWeb3React} from '@web3-react/core';

import {chainHasStakingOpen} from '../../../services/staking';
import {SafeMuiLink} from '../../Common/links';

import './styles.scss';

export default function StakingInfo() {
    const context = useWeb3React();
    const {chainId} = context;

    const subtitle = chainHasStakingOpen(chainId) ? (
        <>Staking will lock your tokens for a minimum of 7 days </>
    ) : (
        <>Classic staking is closed for new stakes</>
    );

    const text = chainHasStakingOpen(chainId) ? (
        <>
            You will need to unstake to collect your rewards. Rewards are not
            automatically staked. Unstaking is available after 7 days.
        </>
    ) : (
        <>
            <Typography>
                The classic staking rewards program ended on May 4th, so new
                stakes were automatically disabled by the smart contracts on
                April 27th as{' '}
                <SafeMuiLink
                    href="https://docs.pantherprotocol.io/launchdao/voting-proposals/3-launch/staking"
                    underline="always"
                    color="inherit"
                >
                    previously
                </SafeMuiLink>{' '}
                <SafeMuiLink
                    href="https://docs.pantherprotocol.io/dao/governance/proposal-3-polygon-extension/staking"
                    underline="always"
                    color="inherit"
                >
                    scheduled
                </SafeMuiLink>
                .
            </Typography>
            <p>
                You can still unstake{' '}
                <SafeMuiLink
                    href="https://docs.pantherprotocol.io/dao/support/faq/staking#when-unstake"
                    underline="always"
                    color="inherit"
                >
                    at any time
                </SafeMuiLink>
                , and there is no deadline for claiming rewards. However active
                stakes have ceased to earn further rewards.
            </p>
            <p>
                Advanced Staking is live now - find details{' '}
                <SafeMuiLink
                    href="https://blog.pantherprotocol.io/advanced-staking-overview/"
                    underline="always"
                    color="inherit"
                >
                    here
                </SafeMuiLink>
                !
            </p>
        </>
    );

    return (
        <Box className="staking-info-container">
            <Typography variant="subtitle2" className="staking-info-title">
                {subtitle}
            </Typography>
            <Box className="staking-info-text">{text}</Box>
        </Box>
    );
}
